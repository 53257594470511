<template>
  <div class="dialog">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item label="设备名称：" prop="name">
        <el-input
          v-model="ruleForm.name"
          placeholder="默认名称：类型+编号"
          @input="handleOnKeyup('name')"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备编号：" prop="deviceId">
        <el-input
          v-model="ruleForm.deviceId"
          placeholder="请输入设备编号"
          @input="handleOnKeyup('deviceId')"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备类型：" prop="typeName">
        <el-select
          v-model="ruleForm.typeName"
          placeholder="请选择设备类型"
          clearable
          @change="handleChangeType"
        >
          <el-option
            v-for="item in mode.typeArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号：" prop="type">
        <el-select
          v-model="ruleForm.type"
          placeholder="请选择设备型号"
          clearable
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="设备服务器：" prop="mqttId">
        <el-select
          v-model="ruleForm.mqttId"
          placeholder="请选择设备服务器"
          clearable
        >
          <el-option
            v-for="item in hotList"
            :key="item.id"
            :label="item.serverHost"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="企业组织：" prop="orgParentId">
        <el-select
          v-model="ruleForm.orgParentId"
          placeholder="请选择企业组织"
          clearable
          @change="handleChangeOrgParent"
        >
          <el-option
            v-for="item in sidebarData"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="园区地块：" prop="orgId">
        <el-select
          v-model="ruleForm.orgId"
          placeholder="请选择园区地块"
          clearable
          @change="handleChangeOrg"
        >
          <el-option
            v-for="item in options3"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="btn-box">
        <el-button class="btn" type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AddDevice",
  props: {
    handleClose: Function,
    sidebarData: Array,
    getDeviceSave: Function,
    mode: Object,
    hotList: Array
  },
  data() {
    return {
      ruleForm: {
        name: "",
        deviceId: "",
        type: "",
        typeName: "",
        mqttId: "",
        orgId: "",
        orgName: "",
        orgParentId: "",
        orgParentName: "",
      },
      rules: {
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        deviceId: [
          { required: true, message: "请输入设备编号", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        typeName: [
          { required: true, message: "请选择设备型号", trigger: "change" },
        ],
        mqttId: [
          { required: true, message: "请选择设备服务器", trigger: "change" },
        ],
        orgId: [
          { required: true, message: "请选择企业组织", trigger: "change" },
        ],
        orgParentId: [
          { required: true, message: "请选择园区地块", trigger: "change" },
        ],
      },
      options1: [],
      options3: [],
    };
  },
  methods: {
    handleOnKeyup(type) {
      console.log(type);
      this.ruleForm[type] = this.handleKeyup(this.ruleForm[type]);
    },
    handleChangeType(id) {
      this.options1 = this.mode[`model${id}List`];
    },
    handleChangeOrgParent(id) {
      if (!id) {
        this.options3 = [];
        return;
      }
      for (let i = 0; i < this.sidebarData.length; i++) {
        let temp = this.sidebarData[i];
        if (id === temp.id) {
          this.ruleForm.orgParentName = temp.label;
          this.options3 = temp.children;
          break;
        }
      }
    },
    handleChangeOrg(id) {
      for (let i = 0; i < this.options3.length; i++) {
        let temp = this.options3[i];
        if (id === temp.id) {
          this.ruleForm.orgName = temp.label;
          break;
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getDeviceSave(this.ruleForm);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box {
  text-align: right;
  .btn {
    margin-right: 20px;
  }
}
</style>
